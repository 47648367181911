import React from 'react';
import PropTypes from 'prop-types';
import { NumericFormat } from 'react-number-format';
import styled from 'styled-components';
import { theme } from '../../../theme';
import VStack from '../Stack/VStack';
import Copy from '../Typography/Copy';
import Label from '../Typography/Label';

const StyledInput = styled(NumericFormat)`
  padding: 0 1rem;
  border: solid
    ${(props) =>
      props.borderColor ? props.borderColor : theme.colors.lightGray};
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : '1px')};
  width: ${(props) => (props.width ? props.width : '100%')};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '9px'};
  font-size: 0.875rem;
  min-height: ${(props) => (props.height ? props.height : '45px')};
  border-color: ${(props) =>
    props.onError ? theme.colors.red : props.borderColor};
  &:focus {
    border-color: #5e3acf;
    border-width: ${(props) => (props.borderWidth ? props.borderWidth : '1px')};
  }
`;

const NumberInput = ({
  name,
  value,
  placeholder = 'Ingresa el monto aquí',
  disabled,
  onChange,
  width,
  error,
  height,
  label,
  borderRadius,
  borderColor,
  borderWidth,
}) => {
  return (
    <VStack space="xsmall">
      {label && (
        <Label color="textBlack" htmlFor={name} fontWeight="regular">
          {label}
        </Label>
      )}
      <StyledInput
        name={name}
        type="text"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        thousandSeparator
        valueIsNumericString={true}
        maxLength={20}
        onValueChange={({floatValue}) => onChange(name, floatValue)}
        width={width}
        borderRadius={borderRadius}
        borderWidth={borderWidth}
        borderColor={borderColor}
        height={height}
        onError={error}
      />
      {label && error && (
        <Copy tag="p" color="red" fontWeight="medium" fontSize="label">
          {error}
        </Copy>
      )}
    </VStack>
  );
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  error: PropTypes.bool,
  borderRadius: PropTypes.string,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.string,
};

export default NumberInput;

import React from 'react';
import PropTypes from 'prop-types';

import Box from '../Box';
import Small from '../Typography/Small';
import Label from '../Typography/Label';
import VStack from '../Stack/VStack';
import MyTooltip from '../MyTooltip';

import useDesignUtils from '../../../hooks/useDesignUtils';
import Interrogation from '../../../assets/images/interrogacion.svg';
import Select from 'react-select';
import styled, { css } from 'styled-components';
import { theme } from '../../../theme';

const ComponentSelect = ({
  options,
  name,
  value,
  label,
  onChange,
  error,
  size,
  placeholder,
  loading,
  variant,
  disabled,
  borderWidth = '0.1px',
  tooltip,
  ...restProps
}) => {
  const { resolveThemeProps } = useDesignUtils();
  const sizeValue = resolveThemeProps('inputSizes', size);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? theme.colors.textBlack : theme.colors.textBlack,
      backgroundColor: state.isSelected
        ? theme.colors.__lightPurple5
        : theme.colors.white,
      width: '95%',
      margin: '2px auto',
      borderRadius: '4px',
    }),
  };

  return (
    <Box width={sizeValue}>
      <VStack space="xsmall">
        <Box display="flex">
          {label && (
            <Label htmlFor={name} fontWeight="regular">
              {label}
            </Label>
          )}
          {tooltip && (
            <MyTooltip text={tooltip}>
              <Box tag="img" src={Interrogation} marginLeft="small" />
            </MyTooltip>
          )}
        </Box>
        <StyledSelect
          name={name}
          options={options}
          value={value}
          onChange={(value) => {
            onChange(name, value);
          }}
          placeholder={placeholder}
          styles={customStyles}
          classNamePrefix="custom-react-select"
          error={error}
          isLoading={loading}
          variant={variant}
          isDisabled={disabled}
          borderWidth={borderWidth}
          {...restProps}
        />
        {error && (
          <Box marginTop="small">
            <Small tag="p" fontWeight="medium" color="red">
              {error}
            </Small>
          </Box>
        )}
      </VStack>
    </Box>
  );
};

const StyledSelect = styled(Select)`
  > .custom-react-select__control {
    cursor: pointer;
    position: relative;
    min-height: 45px;
    border-radius: ${({ theme }) => theme.radius.input};
    border-color: ${({ theme }) => theme.colors.lightGray};
    border-width: ${({ borderWidth }) => borderWidth};
    border-color: ${({ theme, error }) => error && theme.colors.red};

    &--menu-is-open {
      box-shadow: 0 0 0 transparent;

      &:hover {
        border-color: ${({ theme }) => theme.colors.lightGreen};
      }
    }

    &--is-focused {
      box-shadow: 0 0 0 transparent;
      border-color: ${({ theme }) => theme.colors.__darkPurple2};

      &:hover {
        border-color: ${({ theme }) => theme.colors.__darkPurple2};
      }
    }
    .css-1okebmr-indicatorSeparator {
      width: 0;
    }
  }

  ${({ theme, variant }) =>
    variant === 'secondary' &&
    css`
      > .custom-react-select__control {
        .custom-react-select__value-container {
          justify-content: center;

          .custom-react-select__placeholder,
          .custom-react-select__single-value {
            font-weight: bold;
            color: ${theme.colors.purple};
          }
        }

        .custom-react-select__indicator-separator {
          display: none;
        }

        .custom-react-select__indicator {
          position: absolute;
          right: 0;

          svg {
            color: ${theme.colors.black};
          }
        }
      }
    `}
`;

ComponentSelect.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]),
  borderWidth: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.string,
  placeholder: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  tooltip: PropTypes.string,
};

export default React.memo(ComponentSelect);
